@import-normalize;

@import url("https://use.typekit.net/kfo8ori.css");

body {
  margin: 0;
  font-family: work-sans, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3d3d3d;
}

body {
  background-color: #f5f5f5;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

.axis {
  font: 10px sans-serif;
}

.axis path,
.axis line {
  fill: none;
  stroke: #d4d8da;
  stroke-width: 2px;
  shape-rendering: crispEdges;
}

.line {
  fill: none;
  stroke: #6f257f;
  stroke-width: 5px;
}

.overlay {
  fill: none;
  pointer-events: all;
}

.focus circle {
  fill: #f1f3f3;
  stroke: #6f257f;
  stroke-width: 5px;
}

.hover-line {
  stroke: #6f257f;
  stroke-width: 2px;
  stroke-dasharray: 3, 3;
}
